@import "./variables.scss";

.footer {
  background-color: $brand-color-gray;
  color: $white;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  height: 150px;
  position: absolute;

  &-company {
    font-size: 0.8rem;

    &__copyright {
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-bottom: 10px;
      position: absolute;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
}
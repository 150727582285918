.select-album {
  margin: 10px 0;
  position: relative;
  display: block;
  width: 100%;
  background-color: $brand-color-gray;
  border-radius: 10px;
  text-align: center;
  padding: 10px 0;
  color: $white;

  .select-album__input {
    position: absolute;
    opacity: 0;
    width: 0px;
  }
}
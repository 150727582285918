$white: #ffffff;
$black: #000000;
$brand-color: #F8B500;
$brand-color-gray: #393939;
$menu-color: #939393;
$input-color: #DDDDDD;
$error: #ff6464;
$gray: #939393;
$submit-color: #ffa200;
$shadow-color: #e4e4e4;
$line: #1dcd00;
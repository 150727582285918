@import "./variables.scss";

.header {
  height: 60px;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  background-color: $brand-color-gray;

  .main-logo {
    flex-grow: 2;
  }

  .service_name {
    display: block;
    margin: 0px 10px;
    font-size: 19px;
    text-decoration: none;
    color: $white;
  }
}

.side-menu {
  position: fixed;
  left: 50%;
  width: 50%;
  background-color: $brand-color-gray;
  color: $white;
  top: 0px;
  height: 100%;
  z-index: 1;
}

.side-menu-cover {
  width: 50%;
  position: fixed;
  height: 100%;
  top: 0px;
  left: 0px;
}

.side-menu-button {
  margin-left: auto;
  margin-right: 10px;
  border: none !important;
  outline: none !important;
  appearance: none !important;
  background-color: $brand-color-gray;
}

.side-menu-item {
  width: 100%;
  border-bottom: solid 1px $white;
  padding: 20px 0px;
  display: block;
  text-align: center;
  color: $white;
  text-decoration: none;
}